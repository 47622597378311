import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>It is with great trepidation that I must acknowledge this page doesn't exist. <em>Ça m’est égal</em>.</p>
  </Layout>
)

export default NotFoundPage
